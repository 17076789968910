@import '/src/styles/globals.scss';

.candidateDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;

  height: 900px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 12px;
    background-color: $white-color;
  }

  &:hover {
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d8d8d8;
      border-radius: 10px;
      border: 2px solid #fff;
      cursor: pointer;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #888;
    }
  }
}

.asideHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .backTitle {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;

    font-size: 12px;
    font-weight: 400;
    color: $textColor;

    img {
      min-width: 20px;
      transform: rotate(90deg);
    }
  }
}

.btn {
  cursor: pointer;
  justify-self: flex-end;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  min-width: 26px;
  height: 26px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
  }
}

.header {
  margin-top: 14px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;

  .candidateHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .name {
      text-overflow: ellipsis;
      font-size: 16px;
      font-weight: 600;
      color: $textColor;

      display: flex;
      align-items: center;
      gap: 4px;

      .order {
        font-weight: 400;
      }
    }

    .additionalInfo {
      font-size: 12px;
      font-weight: 400;
      color: $placeholderTextColor;
    }
  }

  .heart {
    cursor: pointer;
    min-width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid $inputBorderColor;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      min-width: 20px;
    }
  }

  .changeCandidateBtn {
    cursor: pointer;
    min-width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid $inputBorderColor;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      min-width: 20px;
    }
  }

  .previousCandidate {
    justify-self: flex-start;
    img {
      transform: rotate(90deg);
    }
  }

  .nextCandidate {
    justify-self: flex-end;
    img {
      transform: rotate(-90deg);
    }
  }
}

.aiInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 22px;

  background-color: $greyBackgroundColor;
  border: 1px solid $dividerColor;
  border-radius: 6px;

  .similarity {
    display: flex;
    align-items: center;
    gap: 8px;

    p {
      font-size: 14px;
      font-weight: 400;
      color: $textColor;

      span {
        color: $placeholderTextColor;
      }
    }
  }

  .likeButtons {
    display: flex;
    align-items: center;
    gap: 6px;

    .likeBtn {
      min-width: 25px;
      height: 25px;
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
    }

    .dislike {
      transform: rotate(180deg);
    }
  }
}

.cvBtn {
  font-size: 14px;
  font-weight: 600;

  width: fit-content;
  height: fit-content;
  padding: 12px 37px;

  background: none;

  border: 1px solid $inputBorderColor;
  border-radius: 6px;

  cursor: pointer;
  transition: all linear 0.3s;

  &:hover {
    background-color: $primaryBtnColor;
    color: $white-color;
  }
}

.resume {
  * {
    max-width: calc(100% - 12px) !important;
  }
}

.headerText {
  padding: 0px 0px 0px 50px;
  font-size: 34px;
  font-weight: 400;
}

.notes {
  padding-top: 20px;
}
