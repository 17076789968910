@import '/src/styles/globals.scss';

.matchingKeywordsContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.matchingKeywordsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.matchingKeywordsTitle {
  display: flex;
  flex-direction: column;
  gap: 8px;

  h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
}

.textarea {
  resize: vertical;
  height: 55px;
  width: 100%;

  padding: 15px;
  margin-bottom: 40px;

  font-size: 14px;
  color: black;
  font-weight: 400;

  border: 1px solid $inputBorderColor;
  border-radius: 6px;

  &:focus {
    border: 1px solid $primaryBtnColor;
    outline: none;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 14px;
    color: $placeholderTextColor;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d8d8d8;
    border-radius: 10px;
    border: 2px solid #fff;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}
