@import '/src/styles/globals.scss';

.candidatesContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;

  height: 800px;
  padding-bottom: 50px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 12px;
    background-color: $white-color;
  }

  &:hover {
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d8d8d8;
      border-radius: 10px;
      border: 2px solid #fff;
      cursor: pointer;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #888;
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 18px;

  h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
  }
}

.searchForm {
  display: flex;
  align-items: flex-end;
  gap: 15px;

  .selectContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;

    font-size: 14px;
    font-weight: 400;
    line-height: 21px;

    .select {
      width: 195px;
      color: $placeholderTextColor;
      border: 1px solid $inputBorderColor;
      border-radius: 6px;
    }
  }

  .searchInput {
    width: 240px;
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid $inputBorderColor;
    border-radius: 6px;
  }
}

.tabs {
  width: 100%;
}

.tab {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  font-size: 14px;
  font-weight: 400;
  color: $textColor;

  padding: 14px 6px;
  margin: 24px 12px 16px;
  margin-left: 0;

  &:hover {
    border-bottom: 2px solid $darkPurpleBorderColor;
  }
}

.activeTab {
  font-weight: 600;
  border-bottom: 2px solid $darkPurpleBorderColor;
}

.candidatesList {
  list-style: none;
  padding: 0;
  width: 100%;

  .candidateItem:not(:last-child) {
    border-bottom: 1px solid $dividerColor;
  }

  .candidateItem {
    display: flex;
    align-items: center;
    gap: 16px;
    list-style: none;
    padding: 14px 16px;

    &:hover {
      background-color: $greyBackgroundColor;
    }

    .order {
      min-width: 18px;
      color: $placeholderTextColor;
      font-size: 14px;
      font-weight: 400;
    }

    .candidateName {
      width: 100%;
      font-size: 16px;
      font-weight: 600;
      color: $textColor;
      text-overflow: ellipsis;

      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .score {
      min-width: 55px;
      font-size: 14px;
      font-weight: 400;
      color: $textColor;
      justify-self: flex-end;
    }

    .dateAdded {
      min-width: 55px;
      font-size: 14px;
      font-weight: 400;
      color: $textColor;
      justify-self: flex-end;
    }

    .avengaEmployee {
      min-width: 25px;
      justify-self: flex-end;
    }

    .avengaEmployee svg {
      vertical-align: middle;
    }

    .btn {
      cursor: pointer;
      justify-self: flex-end;
      background: none;
      border: none;
      outline: none;
      padding: 0;
      min-width: 20px;
      height: 20px;

      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.candidatesListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.showMoreCandidates {
  font-size: 14px;
  font-weight: 400;

  width: fit-content;
  height: fit-content;
  padding: 12px 90px;

  border: 1px solid transparent;
  background: $primaryBtnColor;
  color: $white-color;

  border-radius: 6px;

  cursor: pointer;
  transition: all linear 0.3s;

  &:hover {
    background-color: transparent;
    border: 1px solid $inputBorderColor;
    color: $textColor;
  }
}
